import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Card
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Surface
import androidx.compose.material.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import gsb_kundalibhagya_website.composeapp.generated.resources.Res
import gsb_kundalibhagya_website.composeapp.generated.resources.apple
import gsb_kundalibhagya_website.composeapp.generated.resources.facebook
import gsb_kundalibhagya_website.composeapp.generated.resources.google
import gsb_kundalibhagya_website.composeapp.generated.resources.headerGSB
import gsb_kundalibhagya_website.composeapp.generated.resources.ic_launcher_playstore
import gsb_kundalibhagya_website.composeapp.generated.resources.instagram
import gsb_kundalibhagya_website.composeapp.generated.resources.landscape
import gsb_kundalibhagya_website.composeapp.generated.resources.mail
import gsb_kundalibhagya_website.composeapp.generated.resources.portrait
import gsb_kundalibhagya_website.composeapp.generated.resources.qr
import gsb_kundalibhagya_website.composeapp.generated.resources.twitter
import gsb_kundalibhagya_website.composeapp.generated.resources.whatsapp
import org.jetbrains.compose.resources.painterResource
import theme.InterBold

@Composable
fun App() {
    MaterialTheme {
        var portrait by remember { mutableStateOf(false) }
        val uriHandler = LocalUriHandler.current
        Surface(
            modifier = Modifier.fillMaxSize(),
            color = MaterialTheme.colors.background
        ) {
            Box(Modifier.fillMaxSize().onSizeChanged {
                portrait = it.width < it.height
            }) {
                Image(
                    painterResource(if (portrait) Res.drawable.portrait else Res.drawable.landscape),
                    contentDescription = "Background",
                    modifier = Modifier.fillMaxSize(),
                    contentScale = ContentScale.FillBounds
                )
                Column(Modifier.fillMaxSize()) {
                    Row(
                        modifier = Modifier.fillMaxWidth().height(80.dp).background(Color.White)
                            .padding(10.dp),
                        verticalAlignment = Alignment.CenterVertically,
                    ) {
                        Image(
                            painterResource(Res.drawable.ic_launcher_playstore),
                            contentDescription = "Icon",
                        )
                        Spacer(Modifier.width(10.dp))
                        Text(
                            text = "GSB-KundaliBhagya",
                            color = Color.Black,
                            fontSize = 24.sp,
                            fontFamily = InterBold(),
                            fontWeight = FontWeight(600),
                            modifier = Modifier,
                        )
                    }
                    Spacer(Modifier.height(30.dp))
                    LazyColumn {
                        item {
                            Box(
                                modifier = Modifier.fillMaxWidth()
                                    .padding(horizontal = 25.dp, vertical = 25.dp).background(
                                        brush = Brush.linearGradient(
                                            colors = listOf(
                                                Color(0xFFF0F0F0),
                                                Color(0xFFE8E8E8),
                                                Color(0xFFD0D0D0),
                                                Color(0xFFC8C8C8),
                                                Color(0xFFF5F5F5),
                                            )
                                        ),
                                        shape = RoundedCornerShape(12.dp)
                                    ),
                            ) {
                                Column(horizontalAlignment = Alignment.CenterHorizontally, modifier = Modifier.padding(12.dp)) {
                                    Text(
                                        text = "About GSB-KundaliBhagya",
                                        color = Color.Black,
                                        fontSize = 18.sp,
                                        fontFamily = InterBold(),
                                        fontWeight = FontWeight(600),
                                        modifier = Modifier,
                                    )
                                    Spacer(Modifier.height(15.dp))
                                    Text(
                                        text = "GSB-KundaliBhagya is a FREE Matrimony Application exclusively for Konkani speaking GSB community.",
                                        color = Color(0xFF333333),
                                        fontSize = 14.sp,
                                        fontFamily = InterBold(),
                                        fontWeight = FontWeight(600),
                                        modifier = Modifier,
                                        textAlign = TextAlign.Center
                                    )
                                    Spacer(Modifier.height(15.dp))
                                    Image(
                                        painterResource(Res.drawable.headerGSB),
                                        contentDescription = "Icon",
                                    )
                                    Spacer(Modifier.height(15.dp))
                                    Text(
                                        text = "One can create profile, search and short-list the profiles using this Application. One can also set the preference so that he/she gets preferred profiles in the Home page of the Application.  \n\nDownload for FREE, create profile for FREE, search your dream partner for FREE.  \n\nFor any assistance/ suggestion/ feedback, feel free to contact us at:",
                                        color = Color(0xFF333333),
                                        fontSize = 14.sp,
                                        fontFamily = InterBold(),
                                        fontWeight = FontWeight(600),
                                        modifier = Modifier,
                                        textAlign = TextAlign.Center
                                    )
                                    Spacer(Modifier.height(15.dp))
                                    Text(
                                        text = "gsbkundalibhagya@gmail.com",
                                        color = Color.Blue,
                                        fontSize = 14.sp,
                                        fontFamily = InterBold(),
                                        fontWeight = FontWeight(600),
                                        modifier = Modifier.clickable {
                                            uriHandler.openUri("mailto:gsbkundalibhagya@gmail.com")
                                        },
                                        textAlign = TextAlign.Center
                                    )
                                    Spacer(Modifier.height(15.dp))
                                    Row(modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.SpaceEvenly) {
                                        Image(
                                            painterResource(Res.drawable.facebook),
                                            contentDescription = "Icon",
                                            modifier = Modifier.width(30.dp).height(30.dp).clickable {
                                                uriHandler.openUri("https://www.facebook.com/GSBKundaliBhagyaPage/")
                                            }
                                        )
                                        Image(
                                            painterResource(Res.drawable.twitter),
                                            contentDescription = "Icon",
                                            modifier = Modifier.width(30.dp).height(30.dp).clickable {
                                                uriHandler.openUri("https://twitter.com/GKundalibhagya")
                                            }
                                        )
                                        Image(
                                            painterResource(Res.drawable.instagram),
                                            contentDescription = "Icon",
                                            modifier = Modifier.width(30.dp).height(30.dp).clickable {
                                                uriHandler.openUri("https://www.instagram.com/gsbkundalibhagya/")
                                            }
                                        )
                                        Image(
                                            painterResource(Res.drawable.mail),
                                            contentDescription = "Icon",
                                            modifier = Modifier.width(30.dp).height(30.dp).clickable {
                                                uriHandler.openUri("mailto:gsbkundalibhagya@gmail.com")
                                            }
                                        )
                                        Image(
                                            painterResource(Res.drawable.whatsapp),
                                            contentDescription = "Icon",
                                            modifier = Modifier.width(30.dp).height(30.dp).clickable {
                                                uriHandler.openUri("https://wa.me/+917892173360")
                                            }
                                        )
                                    }
                                    Spacer(Modifier.height(30.dp))
                                    Text(
                                        text = "App is available for Android & iOS",
                                        color = Color.Black,
                                        fontSize = 24.sp,
                                        fontFamily = InterBold(),
                                        fontWeight = FontWeight(600),
                                        modifier = Modifier,
                                        textAlign = TextAlign.Center
                                    )
                                    Spacer(Modifier.height(15.dp))
                                    Row(horizontalArrangement = Arrangement.SpaceAround) {
                                        Image(
                                            painter = painterResource(Res.drawable.google),
                                            contentDescription = "Icon",
                                            modifier = Modifier.weight(1f).clickable {
                                                uriHandler.openUri("https://play.google.com/store/apps/details?id=com.gsb.kundali.bhagya.android")
                                            },
                                            alignment = Alignment.CenterEnd
                                        )
                                        Spacer(Modifier.width(25.dp))
                                        Image(
                                            painter = painterResource(Res.drawable.apple),
                                            contentDescription = "Icon",
                                            modifier = Modifier.weight(1f).clickable {
                                                uriHandler.openUri("https://apps.apple.com/us/app/gsb-kundalibhagya/id1582004064")
                                            },
                                            alignment = Alignment.CenterStart
                                        )
                                    }
                                    Spacer(Modifier.height(30.dp))
                                    Text(
                                        text = "Support Us",
                                        color = Color.Black,
                                        fontSize = 24.sp,
                                        fontFamily = InterBold(),
                                        fontWeight = FontWeight(600),
                                        modifier = Modifier,
                                    )
                                    Spacer(Modifier.height(15.dp))
                                    Image(
                                        painter = painterResource(Res.drawable.qr),
                                        contentDescription = "Icon",
                                        modifier = Modifier.width(200.dp).height(200.dp).clickable {

                                        },
                                        alignment = Alignment.CenterStart
                                    )
                                    Spacer(Modifier.height(15.dp))
                                    Text(
                                        text = "Our services are FREE, however there is a cost involved in managing & maintaining the service, \nWe request you to please support us in any small way possible by either donation (UPI ID - gsbkundalibhagya@ybl) or by sharing the GSB-KundaliBhagya Application among your family and friends \nThank You",
                                        color = Color(0xFF333333),
                                        fontSize = 14.sp,
                                        fontFamily = InterBold(),
                                        fontWeight = FontWeight(600),
                                        modifier = Modifier,
                                        textAlign = TextAlign.Center
                                    )
                                    Spacer(Modifier.height(15.dp))
                                    Row(modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.SpaceEvenly) {
                                        Text(
                                            text = "Privacy Policy",
                                            color = Color.Blue,
                                            fontSize = 14.sp,
                                            fontFamily = InterBold(),
                                            fontWeight = FontWeight(600),
                                            modifier = Modifier.clickable {
                                                uriHandler.openUri("https://gsb-kundalibhagya.flycricket.io/privacy.html")
                                            },
                                            textAlign = TextAlign.Center
                                        )
                                        Text(
                                            text = "Terms & Conditions",
                                            color = Color.Blue,
                                            fontSize = 14.sp,
                                            fontFamily = InterBold(),
                                            fontWeight = FontWeight(600),
                                            modifier = Modifier.clickable {
                                                uriHandler.openUri("https://gsb-kundalibhagya.flycricket.io/terms.html")
                                            },
                                            textAlign = TextAlign.Center
                                        )
                                    }
                                    Spacer(Modifier.height(30.dp))
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}