@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package gsb_kundalibhagya_website.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val apple: DrawableResource by 
      lazy { init_apple() }

  public val facebook: DrawableResource by 
      lazy { init_facebook() }

  public val google: DrawableResource by 
      lazy { init_google() }

  public val headerGSB: DrawableResource by 
      lazy { init_headerGSB() }

  public val ic_launcher_playstore: DrawableResource by 
      lazy { init_ic_launcher_playstore() }

  public val instagram: DrawableResource by 
      lazy { init_instagram() }

  public val landscape: DrawableResource by 
      lazy { init_landscape() }

  public val mail: DrawableResource by 
      lazy { init_mail() }

  public val portrait: DrawableResource by 
      lazy { init_portrait() }

  public val qr: DrawableResource by 
      lazy { init_qr() }

  public val twitter: DrawableResource by 
      lazy { init_twitter() }

  public val whatsapp: DrawableResource by 
      lazy { init_whatsapp() }
}

internal val Res.drawable.apple: DrawableResource
  get() = CommonMainDrawable0.apple

private fun init_apple(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:apple",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/gsb_kundalibhagya_website.composeapp.generated.resources/drawable/apple.png", -1, -1),
    )
)

internal val Res.drawable.facebook: DrawableResource
  get() = CommonMainDrawable0.facebook

private fun init_facebook(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:facebook",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/gsb_kundalibhagya_website.composeapp.generated.resources/drawable/facebook.png", -1, -1),
    )
)

internal val Res.drawable.google: DrawableResource
  get() = CommonMainDrawable0.google

private fun init_google(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:google",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/gsb_kundalibhagya_website.composeapp.generated.resources/drawable/google.png", -1, -1),
    )
)

internal val Res.drawable.headerGSB: DrawableResource
  get() = CommonMainDrawable0.headerGSB

private fun init_headerGSB(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:headerGSB",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/gsb_kundalibhagya_website.composeapp.generated.resources/drawable/headerGSB.jpg", -1, -1),
    )
)

internal val Res.drawable.ic_launcher_playstore: DrawableResource
  get() = CommonMainDrawable0.ic_launcher_playstore

private fun init_ic_launcher_playstore(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_launcher_playstore",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/gsb_kundalibhagya_website.composeapp.generated.resources/drawable/ic_launcher-playstore.png", -1, -1),
    )
)

internal val Res.drawable.instagram: DrawableResource
  get() = CommonMainDrawable0.instagram

private fun init_instagram(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:instagram",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/gsb_kundalibhagya_website.composeapp.generated.resources/drawable/instagram.png", -1, -1),
    )
)

internal val Res.drawable.landscape: DrawableResource
  get() = CommonMainDrawable0.landscape

private fun init_landscape(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:landscape",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/gsb_kundalibhagya_website.composeapp.generated.resources/drawable/landscape.png", -1, -1),
    )
)

internal val Res.drawable.mail: DrawableResource
  get() = CommonMainDrawable0.mail

private fun init_mail(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:mail",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/gsb_kundalibhagya_website.composeapp.generated.resources/drawable/mail.png", -1, -1),
    )
)

internal val Res.drawable.portrait: DrawableResource
  get() = CommonMainDrawable0.portrait

private fun init_portrait(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:portrait",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/gsb_kundalibhagya_website.composeapp.generated.resources/drawable/portrait.png", -1, -1),
    )
)

internal val Res.drawable.qr: DrawableResource
  get() = CommonMainDrawable0.qr

private fun init_qr(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:qr",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/gsb_kundalibhagya_website.composeapp.generated.resources/drawable/qr.jpg", -1, -1),
    )
)

internal val Res.drawable.twitter: DrawableResource
  get() = CommonMainDrawable0.twitter

private fun init_twitter(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:twitter",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/gsb_kundalibhagya_website.composeapp.generated.resources/drawable/twitter.png", -1, -1),
    )
)

internal val Res.drawable.whatsapp: DrawableResource
  get() = CommonMainDrawable0.whatsapp

private fun init_whatsapp(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:whatsapp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/gsb_kundalibhagya_website.composeapp.generated.resources/drawable/whatsapp.png", -1, -1),
    )
)
